import { useQuery } from '@tanstack/react-query';

import { getOfferById } from '../../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../../common/api/queryKeys';
import { Offer } from '../../../model/offer.model';

export const useQuotationState = (id) => {
    return useQuery({
        queryKey: queryKeys.offer(id),
        queryFn: () => getOfferById(id),
        staleTime: Infinity,
        select: (data) => {
            return new Offer({
                ...data,
                vendorReference: data.vendorReference ?? '',
                dateDelivery: data.dateDelivery || null,
                quoteValidityTime: data.quoteValidityTime ?? '',
                items: data.items.map((item) => ({
                    ...item,
                    physicalSupplierName: item.physicalSupplierName ?? '',
                })),
            });
        },
    });
};
