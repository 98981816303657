import { useField } from 'formik';

import { SegmentedControl, SegmentedControlProps } from '../../../cdl/SegmentedControl/SegmentedControl';
import { LabelText } from '../../LabelText/LabelText';

interface FormikSegmentedControlProps<T extends string> extends Pick<SegmentedControlProps<T>, 'options'> {
    name: string;
    label: string;
}

export const FormikSegmentedControl = <T extends string>({ name, options, label }: FormikSegmentedControlProps<T>) => {
    const [field, , helper] = useField(name);

    return (
        <div>
            <LabelText>{label}</LabelText>
            <SegmentedControl<T> options={options} value={field.value} onChange={(value) => helper.setValue(value)} />
        </div>
    );
};
