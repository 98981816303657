import { Content as RadixTooltipContent } from '@radix-ui/react-tooltip';
import styled from 'styled-components';

import { theme } from '../ui/theme';

export const Content = styled(RadixTooltipContent)`
    background-color: white;
    border: 1px solid ${theme.colors['dark-blue'][6]};
    box-shadow: ${theme.shadows.medium};
    border-radius: ${theme.radii[1]};
    padding: ${`${theme.space[2]} ${theme.space[4]}`};
    max-width: 300px;
    white-space: pre-wrap;
    z-index: 9998;
`;
