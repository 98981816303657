import styled from 'styled-components';

import { AccessLevel } from '../../../../../types/AccessLevel';
import { UserType } from '../../../../../types/UserType';
import { Button } from '../../../../cdl/Button/Button';
import { ReadOnlyField } from '../../../../cdl/ReadOnlyField/ReadOnlyField';
import { Tag } from '../../../../cdl/Tag/Tag';
import { Text } from '../../../../cdl/Text/Text';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { ButtonGroup } from '../../../../common/buttons/ButtonGroup';
import { translate } from '../../../../common/helpers/translate.helper';
import { useCopyToClipboard } from '../../../../common/hooks/useCopyToClipboard';
import { useEnums } from '../../../../common/hooks/useEnums';
import { IconCopy } from '../../../../common/icons/cdl/Copy';
import { Box } from '../../../../common/ui/Box';
import { useApiKeyCreate } from '../hooks/useApiKeyCreate';

import { ApiKeyFormState } from './ApiKeyBaseForm';
import { ApiKeyForm } from './ApiKeyForm';

const CreationContentContainer = styled(Box).attrs({
    padding: 6,
    display: 'grid',
    gap: 6,
})``;

interface CreateApiKeyModalContentProps {
    groupId: string;
    userType: UserType.CUSTOMER | UserType.SUPPLIER;
    onDismiss: () => void;
}

export const CreateApiKeyModalContent = ({ groupId, userType, onDismiss }: CreateApiKeyModalContentProps) => {
    const apiKeyCreateMutation = useApiKeyCreate();
    const { getHumanReadableValue } = useEnums();
    const { addErrorToast } = useToasts();
    const copyToClipboard = useCopyToClipboard();

    const onSubmit = async (values: ApiKeyFormState) => {
        const companyAccesses = values.companyIds.map((companyId) => {
            return {
                companyId: companyId,
                accessLevel: values.companyAccessLevel!,
            };
        });

        await apiKeyCreateMutation.mutateAsync(
            {
                name: values.name,
                groupId: groupId,
                userType: userType,
                accessLevel: AccessLevel.WRITE,
                companyAccesses: companyAccesses,
            },
            { onError: () => addErrorToast(translate('apiKey.creationError')) }
        );
    };

    if (apiKeyCreateMutation.isSuccess) {
        return (
            <CreationContentContainer>
                <Text variant="title">{translate('apiKey.saveTitle')}</Text>
                <Text variant="small">{translate('apiKey.saveDescription')}</Text>

                <ReadOnlyField
                    value={apiKeyCreateMutation.data.data.key}
                    trailingVisual={
                        <Button
                            size="small"
                            emphasis="high"
                            onClick={() => copyToClipboard(apiKeyCreateMutation.data.data.key)}
                            leadingVisual={<IconCopy height={16} width={16} />}
                        >
                            {translate('btn.copy')}
                        </Button>
                    }
                />

                <Box display="flex" flexDirection="column" alignItems="start" gap={2}>
                    <Text variant="small" fontWeight="semiBold">
                        {translate('apikey.companyaccesslevel')}
                    </Text>
                    <Tag variant="default">
                        {getHumanReadableValue('accessLevel', apiKeyCreateMutation.data.data.accessLevel)}
                    </Tag>
                </Box>

                <ButtonGroup justifyContent="end">
                    <Button onClick={onDismiss}>{translate('btn.done')}</Button>
                </ButtonGroup>
            </CreationContentContainer>
        );
    }

    return (
        <CreationContentContainer>
            <Text variant="title">{translate('apikey.createtitle')}</Text>

            <ApiKeyForm
                onSubmit={onSubmit}
                groupId={groupId}
                buttonText={translate('apiKey.createSecretKey')}
                userType={userType}
                onCancel={onDismiss}
            />
        </CreationContentContainer>
    );
};
