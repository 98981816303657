import { ApiKeyMessage } from '../../../../../types/ApiKeyMessage';
import { UserType } from '../../../../../types/UserType';
import { TableBuilder } from '../../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../../cdl/TableBuilder/TableBuilderColumn';
import { Tag } from '../../../../cdl/Tag/Tag';
import { Text } from '../../../../cdl/Text/Text';
import { ButtonGroup } from '../../../../common/buttons/ButtonGroup';
import { GenericError } from '../../../../common/Error/GenericError';
import { formatCompanyName } from '../../../../common/helpers/formatCompanyName.helper';
import { formatDateTime } from '../../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useEnums } from '../../../../common/hooks/useEnums';
import { useRole } from '../../../../common/hooks/useRole';
import { ListingWithMore } from '../../../../common/ListingWithMore/ListingWithMore';
import { LoadingIndicator } from '../../../../common/LoadingIndicator/LoadingIndicator';
import { TableEmptyState } from '../../../../common/TableEmptyState/TableEmptyState';
import { useApiKeyList } from '../hooks/useApiKeyList';

import { ApiKeyDisplay } from './ApiKeyDisplay';
import { ApiKeySwitch } from './ApiKeySwitch';
import { EditApiKeyButton } from './EditApiKeyButton';
import { RevokeApiKeyButton } from './RevokeApiKeyButton';

interface ApiKeyListProps {
    groupId: string;
    userType: UserType.CUSTOMER | UserType.SUPPLIER;
}

export const ApiKeysTable = (props: ApiKeyListProps) => {
    const apiKeyListQuery = useApiKeyList(props.groupId, props.userType);
    const role = useRole();
    const { getHumanReadableValue } = useEnums();

    if (apiKeyListQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (apiKeyListQuery.isError) {
        return <GenericError />;
    }

    if (!apiKeyListQuery.data.length) {
        return <TableEmptyState text={translate('apikey.emptylist')} />;
    }

    return (
        <TableBuilder data={apiKeyListQuery.data}>
            <TableBuilderColumn<ApiKeyMessage> header={translate('apikey.name')}>
                {(row) => row.name}
            </TableBuilderColumn>
            <TableBuilderColumn<ApiKeyMessage> header={translate('apikey.key')} width="150px">
                {(row) => <ApiKeyDisplay apiKey={row.key} />}
            </TableBuilderColumn>
            <TableBuilderColumn<ApiKeyMessage> header={translate('apikey.datecreated')} width="200px">
                {(row) => formatDateTime({ date: row.dateCreated })}
            </TableBuilderColumn>
            <TableBuilderColumn<ApiKeyMessage> header={translate('apikey.lastUsed')} width="200px">
                {(row) => {
                    if (!row.dateLastUsed) {
                        return <Text color="foreground.subtle">{translate('apikey.neverUsed')}</Text>;
                    }
                    return formatDateTime({ date: row.dateLastUsed });
                }}
            </TableBuilderColumn>
            <TableBuilderColumn<ApiKeyMessage> header={translate('apikey.companies')}>
                {(row) => {
                    if (!row.companyAccesses?.length) {
                        return null;
                    }

                    const companies = row.companyAccesses
                        .map((companyAccess) => {
                            return formatCompanyName({ company: companyAccess.company, includeType: role.isAdmin() });
                        })
                        .filter(Boolean) as string[];

                    return <ListingWithMore>{companies}</ListingWithMore>;
                }}
            </TableBuilderColumn>
            <TableBuilderColumn<ApiKeyMessage> header={translate('apikey.permission')} width="150px">
                {(row) => {
                    const accessLevel = row.companyAccesses?.[0]?.accessLevel;
                    if (!accessLevel) {
                        return null;
                    }
                    return <Tag variant="default">{getHumanReadableValue('accessLevel', accessLevel)}</Tag>;
                }}
            </TableBuilderColumn>
            <TableBuilderColumn<ApiKeyMessage> header={translate('apikey.enableColumnHeader')} width="100px">
                {(row) => <ApiKeySwitch apiKey={row} groupId={props.groupId} userType={props.userType} />}
            </TableBuilderColumn>
            <TableBuilderColumn<ApiKeyMessage> width="110px" header="">
                {(row) => (
                    <ButtonGroup>
                        <EditApiKeyButton groupId={props.groupId} apiKey={row} userType={props.userType} />
                        <RevokeApiKeyButton apiKey={row} />
                    </ButtonGroup>
                )}
            </TableBuilderColumn>
        </TableBuilder>
    );
};
