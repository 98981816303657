import { QueryKey, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useEffect } from 'react';

import { PageMessage } from '../../../types/PageMessage';

interface UsePaginationQueryInterface {
    queryKey: QueryKey;
    queryFn: any;
    options?: any;
    prefetchQueryKey: QueryKey;
    prefetchQueryFn: any;
}

export type UsePaginationQueryResult<T> = UseQueryResult<PageMessage<T>> & {
    pageTotal: number;
};

export const usePaginationQuery = <T>({
    queryKey,
    queryFn,
    options = {},
    prefetchQueryKey,
    prefetchQueryFn,
}: UsePaginationQueryInterface): UsePaginationQueryResult<T> => {
    const fiveMinutesInMilliseconds = 5 * 60 * 1000;

    const queryClient = useQueryClient();
    const query = useQuery<PageMessage<T>, Error>({ queryKey, queryFn, ...options });

    const pageTotal = query.data ? Math.ceil(query.data?.total / query.data?.pageable.size) : 0;
    const hasMoreData = query.data
        ? query.data.total > (query.data.pageable.page + 1) * query.data.pageable.size
        : false;

    const deps = Array.isArray(queryKey) ? queryKey : [queryKey];

    useEffect(() => {
        if (!hasMoreData || !prefetchQueryKey || !prefetchQueryFn) {
            return;
        }

        queryClient.prefetchQuery({
            queryKey: prefetchQueryKey,
            queryFn: prefetchQueryFn,
            staleTime: fiveMinutesInMilliseconds,
        });
    }, [query.isPending, queryClient, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps

    return {
        ...query,
        pageTotal,
    };
};
