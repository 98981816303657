import { useNavigate } from '@tanstack/react-router';
import { Formik } from 'formik';
import moment from 'moment-timezone';

import { EnergyContentUnit } from '../../../../../types/EnergyContentMessage';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { translate } from '../../../../common/helpers/translate.helper';
import { useMessageCreate } from '../hooks/useMessageCreate';

import { QuoteForm } from './QuoteForm';
import { useOfferUpdate } from '../../../detail/common/hooks/useOfferUpdate';
import { OfferState } from '../../../model/OfferState';

const mapFormStateToOfferRequest = (formValues) => {
    const utcIsoDateDelivery = moment.utc(formValues.dateDelivery).startOf('day').toISOString();

    return {
        ...formValues,
        items: formValues.items.map((item) => {
            return {
                ...item,
                energyContent: item.energyContent?.value
                    ? { value: Number(item.energyContent.value), unit: EnergyContentUnit.MJKG }
                    : null,
            };
        }),
        dateDelivery: utcIsoDateDelivery,
        vendorReference: formValues.vendorReference ? formValues.vendorReference : null,
        state: OfferState.QUOTED,
    };
};

export const QuoteFormWrapper = (props) => {
    const offerUpdateMutation = useOfferUpdate();
    const messageMutation = useMessageCreate();
    const navigate = useNavigate();
    const { addErrorToast } = useToasts();

    const validate = (values) => {
        const errors = {};

        if (!values.dateDelivery) {
            errors.dateDelivery = 'REQUIRED';
        }

        if (!values.supplyMode) {
            errors.supplyMode = 'REQUIRED';
        }

        if (values.supplyModeIncludesDeliveryCosts === undefined) {
            errors.supplyModeIncludesDeliveryCosts = 'REQUIRED';
        }

        if (!values.quoteValidityTime) {
            errors.quoteValidityTime = 'REQUIRED';
        }

        if (
            !values.paymentTermReference ||
            (!values.paymentTermReferenceDays && values.paymentTermReference !== 'PREPAYMENT')
        ) {
            errors.paymentTermReference = 'REQUIRED';
        }

        if (!values.hasValidProducts()) {
            errors.invalidProducts = true;
        }

        if (
            values.surcharges.some((surcharge) => {
                return !surcharge.value?.value || Number(surcharge.value?.value) === 0;
            })
        ) {
            errors.invalidSurcharges = true;
        }
        return errors;
    };

    const navigateToOffer = (offerId) => {
        navigate({ to: '/fuel/offer/$id', params: { id: offerId } });
    };

    const onSubmit = async (values, actions) => {
        try {
            await offerUpdateMutation.mutateAsync({
                offerId: values.id,
                offer: mapFormStateToOfferRequest(values),
            });

            if (!values.message) {
                navigateToOffer(values.id);

                actions.setSubmitting(false);
                return;
            }

            await messageMutation.mutateAsync({
                offerId: values.id,
                message: values.message,
            });

            navigateToOffer(values.id);
            actions.setSubmitting(false);
        } catch {
            addErrorToast(translate('global.genericToastError'));
            actions.setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={props.data}
            validateOnMount
            validate={validate}
            component={QuoteForm}
            onSubmit={onSubmit}
        />
    );
};
