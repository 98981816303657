import { Formik, FormikErrors } from 'formik';

import { AccessLevel } from '../../../../../types/AccessLevel';
import { ApiKeyMessage } from '../../../../../types/ApiKeyMessage';
import { UserType } from '../../../../../types/UserType';
import { translate } from '../../../../common/helpers/translate.helper';

import { ApiKeyBaseForm, ApiKeyFormState } from './ApiKeyBaseForm';

interface ApiKeyFormProps {
    buttonText: string;
    apiKey?: ApiKeyMessage;
    groupId: string;
    onSubmit: (values: ApiKeyFormState) => void;
    userType: UserType.CUSTOMER | UserType.SUPPLIER;
    onCancel: () => void;
}

export const ApiKeyForm = (props: ApiKeyFormProps) => {
    const initialFormikState: ApiKeyFormState = {
        name: props.apiKey?.name ?? '',
        companyIds: props.apiKey?.companyAccesses?.map((it) => it.companyId) ?? [],
        companyAccessLevel: props.apiKey?.companyAccesses[0]?.accessLevel || AccessLevel.READ,
    };

    const validate = (values: ApiKeyFormState) => {
        const errors: FormikErrors<ApiKeyFormState> = {};

        if (!values.name) {
            errors.name = translate('apikey.noNameError');
        }

        if (values.companyIds.length === 0) {
            errors.companyIds = translate('apikey.noCompanySelectedError');
        }

        if (!values.companyAccessLevel) {
            errors.companyAccessLevel = translate('apikey.noAccessLevelSelectedError');
        }

        return errors;
    };

    return (
        <Formik
            initialValues={initialFormikState}
            onSubmit={props.onSubmit}
            validate={validate}
            validateOnMount={false}
            validateOnBlur={false}
        >
            <ApiKeyBaseForm
                groupId={props.groupId}
                buttonText={props.buttonText}
                userType={props.userType}
                onCancel={props.onCancel}
            />
        </Formik>
    );
};
