import { animated } from '@react-spring/web';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

interface ContentProps {
    width?: string;
}

export const AnimatedContent = styled(animated.div)<ContentProps>`
    padding: ${get('space.2')}px ${get('space.4')}px;
    box-shadow: ${get('shadows.medium')};
    background-color: ${get('colors.background.default')};
    border: 1px solid ${get('colors.border.muted')};
    border-radius: ${get('radii.1')}px;
    width: ${(props) => props.width ?? 'auto'};
    transform-origin: var(--radix-popover-content-transform-origin);
    z-index: 9998;
`;
