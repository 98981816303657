import { animated, config, useTransition } from '@react-spring/web';
import { ComponentType } from 'react';
import styled from 'styled-components';

import { IconCheck } from '../../common/icons/cdl/Check';
import { Spinner } from '../../common/Spinner/Spinner';
import { get } from '../../common/ui/get';
import { Text } from '../Text/Text';

const ToastContentWrapper = styled.div<Pick<ToastProps, 'state'>>`
    display: flex;
    align-items: center;
    gap: ${get('space.4')}px;
    background-color: ${(props) =>
        props.state === 'error' ? get('colors.negative.emphasis') : get('colors.background.emphasis')};
    color: ${get('colors.foreground.onEmphasis')};
    border-radius: ${get('radii.1')}px;
    padding: ${get('space.4')}px ${get('space.5')}px;
    z-index: 9999;
`;

type BaseToastProps = {
    isVisible: boolean;
    children: string;
};

type StateAndIcon = BaseToastProps & {
    state: 'error';
    Icon?: ComponentType<{ width?: number; height?: number; color?: string }>;
};

type StateWithoutIcon = BaseToastProps & {
    state: 'success' | 'loading';
    Icon?: never;
};

type DefaultToastProps = BaseToastProps & {
    state?: never;
    Icon?: ComponentType<{ width?: number; height?: number; color?: string }>;
};

type ToastProps = StateAndIcon | StateWithoutIcon | DefaultToastProps;

export const Toast = ({ isVisible, state, children, Icon }: ToastProps) => {
    const transitions = useTransition(isVisible, {
        from: { y: +50, opacity: 0 },
        enter: { y: 0, opacity: 1 },
        leave: { opacity: 0 },
        config: {
            ...config.default,
            tension: 300,
            clamp: true,
        },
    });

    return transitions((style, show) => {
        return show ? (
            <animated.div style={{ ...style }}>
                <ToastContentWrapper state={state}>
                    {state === 'loading' ? <Spinner color="#ffffff" size={18} strokeWidth={4} /> : null}
                    {state === 'success' ? <IconCheck width={20} height={20} color="#ffffff" /> : null}
                    {Icon ? <Icon width={20} height={20} color="#ffffff" /> : null}
                    <Text variant="small">{children}</Text>
                </ToastContentWrapper>
            </animated.div>
        ) : null;
    });
};
