import { useToasts } from '../../cdl/Toast/useToasts';
import { translate } from '../helpers/translate.helper';
import { IconCheck } from '../icons/cdl/Check';

export const useCopyToClipboard = () => {
    const { addErrorToast, addToast } = useToasts();

    return (text: string) =>
        navigator.clipboard
            .writeText(text)
            .catch(() => {
                addErrorToast(translate('copyToClipboard.error'));
            })
            .finally(() => setTimeout(() => addToast(translate('copyToClipboard.success'), IconCheck), 400));
};
