import { useQuery } from '@tanstack/react-query';

import { getOfferById } from '../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { Offer } from '../../model/offer.model';
import { OfferModel } from '../../model/OfferModel';

export const useOfferView = (id: string) => {
    const { isFuel } = useProductContext();

    return useQuery({
        queryKey: queryKeys.offer(id),
        queryFn: () => getOfferById(id),
        staleTime: Infinity,
        select: (offer: OfferModel) => {
            if (!isFuel) {
                return new Offer({
                    ...offer,
                    vendorReference: offer.vendorReference ?? '',
                    buyerReference: offer.buyerReference ?? '',
                    items: offer.items.map((item) => {
                        item.physicalSupplierName = item.physicalSupplierName ?? '';
                        return item;
                    }),
                    surveyor: {
                        name: offer.surveyor?.name ?? '',
                        emailAddress: offer.surveyor?.emailAddress ?? '',
                    },
                }) as any as OfferModel;
            }

            return new Offer({
                ...offer,
                vendorReference: offer.vendorReference ?? '',
                buyerReference: offer.buyerReference ?? '',
                dateDelivery: offer.dateDelivery || null,
            }) as any as OfferModel;
        },
    });
};
