import { usePaginationQuery, UsePaginationQueryResult } from './usePaginationQuery';

interface BasicPaginationQueryParams {
    queryByPage: (page: number) => any;
    page: number;
}

export const useBasicPaginationQuery = <T>({
    queryByPage,
    page,
}: BasicPaginationQueryParams): UsePaginationQueryResult<T> => {
    return usePaginationQuery<T>({
        queryKey: queryByPage(page).queryKey,
        queryFn: queryByPage(page).queryFn,
        prefetchQueryKey: queryByPage(page + 1).queryKey,
        prefetchQueryFn: queryByPage(page + 1).queryFn,
    });
};
