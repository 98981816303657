import { Form, useFormikContext } from 'formik';

import { AccessLevel } from '../../../../../types/AccessLevel';
import { UserType } from '../../../../../types/UserType';
import { Button } from '../../../../cdl/Button/Button';
import { FormikInput } from '../../../../cdl/Input/FormikInput';
import { ButtonGroup } from '../../../../common/buttons/ButtonGroup';
import { FormikCustomerCompanySelect } from '../../../../common/form-elements/formik/FormikCustomerCompanySelect';
import { FormikDebug } from '../../../../common/form-elements/formik/FormikDebug';
import { FormikSegmentedControl } from '../../../../common/form-elements/formik/FormikSegmentedControl';
import { FormikSupplierCompanySelect } from '../../../../common/form-elements/formik/FormikSupplierCompanySelect';
import { translate } from '../../../../common/helpers/translate.helper';
import { useEnums } from '../../../../common/hooks/useEnums';
import { Box } from '../../../../common/ui/Box';

interface PermissionLevelOption {
    label: string;
    value: AccessLevel;
}

interface ApiKeyBaseFormProps {
    buttonText: string;
    groupId: string;
    userType: UserType.CUSTOMER | UserType.SUPPLIER;
    onCancel: () => void;
}

export interface ApiKeyFormState {
    name: string;
    companyIds: string[];
    companyAccessLevel?: AccessLevel;
}

export const ApiKeyBaseForm = (props: ApiKeyBaseFormProps) => {
    const formikContext = useFormikContext();

    const { getHumanReadableValue } = useEnums();

    const sortedAccessLevels = [AccessLevel.READ, AccessLevel.WRITE, AccessLevel.ADMIN];

    const permissionOptions = sortedAccessLevels.map(
        (accessLevel): PermissionLevelOption => ({
            label: getHumanReadableValue('accessLevel', accessLevel),
            value: accessLevel,
        })
    );

    return (
        <Form style={{ height: '100%' }}>
            <Box display="grid" rowGap={6}>
                <FormikInput name="name" label={translate('apikey.name')} />

                {props.userType === UserType.CUSTOMER ? (
                    <FormikCustomerCompanySelect
                        name="companyIds"
                        label={translate('apikey.companies')}
                        placeholder={translate('apiKey.assignedCompaniesPlaceholder')}
                        groupId={props.groupId}
                        isMulti
                    />
                ) : (
                    <FormikSupplierCompanySelect
                        name="companyIds"
                        label={translate('apikey.companies')}
                        placeholder={translate('apiKey.assignedCompaniesPlaceholder')}
                        groupId={props.groupId}
                        isMulti
                    />
                )}

                <FormikSegmentedControl<AccessLevel>
                    name="companyAccessLevel"
                    options={permissionOptions}
                    label={translate('apikey.companyaccesslevel')}
                />

                <ButtonGroup justifyContent="end">
                    <Button type="button" onClick={props.onCancel}>
                        {translate('btn.cancel')}
                    </Button>
                    <Button emphasis="high" type="submit" isLoading={formikContext.isSubmitting}>
                        {props.buttonText}
                    </Button>
                </ButtonGroup>
                <FormikDebug />
            </Box>
        </Form>
    );
};
