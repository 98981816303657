import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const SegmentedControlRadioGroup = styled(RadixRadioGroup.Root)`
    height: 32px;
    padding: ${get('space.2')}px;
    background-color: ${get('colors.background.inset')};
    border-radius: ${get('radii.2')}px;
    width: max-content;
`;
