import { components, MenuProps } from 'react-select';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

interface CustomMenuProps extends MenuProps {
    dropdownWidth?: string;
}

export const CustomMenu = styled(components.Menu)<CustomMenuProps>`
    && {
        box-shadow: none;
        margin-top: ${get('space.2')}px;
        margin-bottom: 0;
        z-index: 9998;
        width: ${(props) => props.dropdownWidth};
    }
`;
