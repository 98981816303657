import { AgentMessage } from '../../../types/AgentMessage';
import { ApprovalRequestMessage } from '../../../types/ApprovalRequestMessage';
import { ContractMessage } from '../../../types/ContractMessage';
import { CounterOffer } from '../../../types/CounterOffer';
import { CustomerCompanyMessage } from '../../../types/CustomerCompanyMessage';
import { FileMessage } from '../../../types/FileMessage';
import { Iso8601DateTimeString } from '../../../types/Iso8601DateTimeString';
import { MoneyMessage } from '../../../types/MoneyMessage';
import { OfferChangesMessage } from '../../../types/OfferChangesMessage';
import { PaymentTermReference } from '../../../types/PaymentTermReference';
import { PortMessage } from '../../../types/PortMessage';
import { ProductContext } from '../../../types/ProductContext';
import { SamplekitMessage } from '../../../types/SamplekitMessage';
import { SupplierMessage } from '../../../types/SupplierMessage';
import { SurchargeMessage } from '../../../types/SurchargeMessage';
import { SurveyorMessage } from '../../../types/SurveyorMessage';
import { UserMessage } from '../../../types/UserMessage';
import { VesselMessage } from '../../../types/VesselMessage';
import { ItemModel } from '../../common/models/ItemModel';

import { OfferState } from './OfferState';

export interface OfferMessage {
    id: string;
    dateCreated?: Iso8601DateTimeString;
    dateUpdated?: Iso8601DateTimeString;
    orderId: string;
    customerId: string;
    supplierId: string;
    enquiryType: EnquiryType;
    state: OfferState;
    dateDelivered?: Iso8601DateTimeString;
    dateConfirmation?: Iso8601DateTimeString;
    dateOrdered?: Iso8601DateTimeString;
    dateEnquired?: Iso8601DateTimeString;
    dateQuoted?: Iso8601DateTimeString;
    dateCanceled?: Iso8601DateTimeString;
    dateAcknowledged?: Iso8601DateTimeString;
    dateInvoiced?: Iso8601DateTimeString;
    vendorReference?: string;
    offerNumber: string;
    customerRead: boolean;
    supplierRead: boolean;
    cancelReason?: string;
    volume: number;
    ppl?: MoneyMessage;
    total: MoneyMessage;
    createdBy?: string;
    updatedBy?: string;
    orderedBy?: string;
    testOffer?: boolean;
    paymentTermReference?: PaymentTermReference;
    paymentTermReferenceDays?: number;
    supplyMode: string;
    supplyModeIncludesDeliveryCosts?: boolean;
    noticeDays?: number;
    quoteValidityTime?: number;
    fileIds?: string[];
    changes: OfferChangesMessage;

    // Order fields
    portId: string;
    dateDelivery: string;
    buyerReference?: string;
    vesselId: string;
    items: ItemModel[];
    surcharges: SurchargeMessage[];
    samplekits?: SamplekitMessage[];
    agentId?: string;
    type: ProductContext;
    contractId?: string;
    eta?: Iso8601DateTimeString;
    etd?: Iso8601DateTimeString;
    validityTime?: number;
    validUntil?: string;
    quoteValidUntil?: string;
    surveyor?: SurveyorMessage;
}

// This should reflect the exact response we get from the GatewayService
// Keep in sync with: https://github.com/closelink/GatewayService/blob/main/src/main/java/net/closelink/gatewayservice/service/clients/pojos/objects/OfferMessageV2.kt
interface GatewayOfferMessage extends OfferMessage {
    customer: CustomerCompanyMessage;
    supplier: SupplierMessage;
    vessel: VesselMessage;
    port: PortMessage;
    createdByUser?: UserMessage;
    updatedByUser?: UserMessage;
    approvalRequests: ApprovalRequestMessage[];
    counterOffers: CounterOffer[];
    agentInformation?: AgentMessage;
    contract?: ContractMessage;
    files: FileMessage[];
}

// Main offer model used in the frontend
// Used for both LUBES and FUEL
// This should only include the properties of the gateway response that are actually used in the frontend
export type OfferModel = Omit<GatewayOfferMessage, 'version' | 'source'> & {
    isCanceled(): () => boolean;
    isEnquiry(): boolean;
    hasOpenCounterOffers(): boolean;
    isSpot(): boolean;
    deliveryPast(): boolean;
    isOrder(): boolean;
    isQuotedEnquiry(): boolean;
    isForwarded(): boolean;
    isAnonymous(): boolean;
    isUnquoted(): boolean;
    wasQuoted(): boolean;
    displayQuoteDetails(): boolean;
    isEditable(): boolean;
    isFinished(): boolean;
    hasValidProducts(): boolean;
};

export enum EnquiryType {
    SPOT = 'SPOT',
    ASSIGNED = 'ASSIGNED',
    FORWARDED = 'FORWARDED',
    ANONYMOUS = 'ANONYMOUS',
}
