import * as RadixTooltip from '@radix-ui/react-tooltip';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

interface ContentProps {
    $maxWidth?: string;
}

export const Content = styled(RadixTooltip.Content)<ContentProps>`
    background: ${get('colors.background.emphasis')};
    color: white;
    padding: 4px 8px;
    box-shadow: ${get('shadows.medium')};
    border-radius: 4px;
    max-width: ${(props) => props.$maxWidth ?? '240px'};
    z-index: 9998;
    white-space: normal;
    overflow-wrap: break-word;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
`;
