import styled from 'styled-components';

import { Text } from '../../../../cdl/Text/Text';
import { useCopyToClipboard } from '../../../../common/hooks/useCopyToClipboard';
import { useRole } from '../../../../common/hooks/useRole';
import { IconClipboardText } from '../../../../common/icons/cdl/ClipboardText';
import { Box } from '../../../../common/ui/Box';
import { truncateApiKey } from '../helpers/truncateApiKey.helper';

const CopyApiKeyButton = styled.button`
    padding: 0;

    svg {
        transition: opacity 75ms ease-in-out;
        opacity: 0;
    }

    &:hover {
        svg {
            opacity: 1;
        }
    }
`;

interface ApiKeyDisplayProps {
    apiKey: string;
}

export const ApiKeyDisplay = ({ apiKey }: ApiKeyDisplayProps) => {
    const role = useRole();

    const copyToClipboard = useCopyToClipboard();

    if (!role.isAdmin()) {
        return <Text variant="small">{truncateApiKey(apiKey)}</Text>;
    }

    return (
        <CopyApiKeyButton onClick={() => copyToClipboard(apiKey)}>
            <Box display="flex" gap={2} alignItems="center">
                <Text variant="small">{truncateApiKey(apiKey)}</Text>
                <IconClipboardText height={16} width={16} />
            </Box>
        </CopyApiKeyButton>
    );
};
