import { useState } from 'react';

import { ApiKeyMessage } from '../../../../../types/ApiKeyMessage';
import { Button } from '../../../../cdl/Button/Button';
import { IconButton } from '../../../../cdl/IconButton/IconButton';
import { Modal } from '../../../../cdl/Modal/Modal';
import { ReadOnlyField } from '../../../../cdl/ReadOnlyField/ReadOnlyField';
import { Text } from '../../../../cdl/Text/Text';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { ButtonGroup } from '../../../../common/buttons/ButtonGroup';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconTrash } from '../../../../common/icons/cdl/Trash';
import { Box } from '../../../../common/ui/Box';
import { truncateApiKey } from '../helpers/truncateApiKey.helper';
import { useApiKeyDelete } from '../hooks/useApiKeyDelete';

interface RevokeApiKeyButtonProps {
    apiKey: ApiKeyMessage;
}

export const RevokeApiKeyButton = ({ apiKey }: RevokeApiKeyButtonProps) => {
    const [isOpen, setOpen] = useState(false);
    const deleteApiKeyMutation = useApiKeyDelete();
    const { addErrorToast } = useToasts();

    const revokeApiKey = async () => {
        await deleteApiKeyMutation.mutateAsync(apiKey.id, {
            onSuccess: () => {
                setOpen(false);
            },
            onError: () => {
                addErrorToast(translate('apiKey.revokeError'));
            },
        });
    };

    return (
        <>
            <IconButton Icon={IconTrash} onClick={() => setOpen(true)} emphasis="transparent" />
            <Modal isOpen={isOpen} dismissible={false} scrollable={false}>
                <Box padding={6} display="grid" gap={5}>
                    <Text variant="title">{translate('apiKey.revokeTitle')}</Text>
                    <Text variant="small">{translate('apiKey.revokeDescription')}</Text>

                    <ReadOnlyField value={truncateApiKey(apiKey.key)} />

                    <ButtonGroup justifyContent="end">
                        <Button onClick={() => setOpen(false)}>{translate('btn.cancel')}</Button>
                        <Button
                            onClick={revokeApiKey}
                            isLoading={deleteApiKeyMutation.isPending}
                            emphasis="high"
                            destructive
                        >
                            {translate('apiKey.revokeKey')}
                        </Button>
                    </ButtonGroup>
                </Box>
            </Modal>
        </>
    );
};
