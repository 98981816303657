import { animated } from '@react-spring/web';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const CalendarContainer = styled(animated.div)`
    box-shadow: 0 8px 24px -2px rgba(25, 54, 67, 0.2);
    background-color: ${get('colors.background.default')};
    border-radius: 16px;
    transform-origin: var(--radix-popover-content-transform-origin);
    width: auto;
    z-index: 9998;
`;
