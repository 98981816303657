import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';

interface OfferDataSheetValueProps {
    changed?: boolean;
}

export const OfferDataSheetValue = styled(Text).attrs<OfferDataSheetValueProps>((props) => ({
    color: props.changed ? 'attention.foreground' : 'foreground.default',
}))<OfferDataSheetValueProps>``;
