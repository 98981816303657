import { EnergyContentMessage } from '../../../types/EnergyContentMessage';
import { LubesProductGroup } from '../../../types/LubesProductGroup';
import { MoneyMessage } from '../../../types/MoneyMessage';
import { ProductMessage } from '../../../types/ProductMessage';

export enum PackType {
    BOTTLE = 'BOTTLE',
    BULK = 'BULK',
    BULK_EX_DRUMS = 'BULK_EX_DRUMS',
    BULK_EX_IBC = 'BULK_EX_IBC',
    DRUMS = 'DRUMS',
    IBCS = 'IBCS',
    PACK = 'PACK',
    PAIL = 'PAIL',
    CARTON = 'CARTON',
}

export enum Unit {
    L = 'L',
    KG = 'KG',
    PIECE = 'PIECE',
}

interface ItemForInitialisation {
    packType: PackType;
    unit: Unit;
    group: LubesProductGroup;
    price: MoneyMessage;
    units: number;
    unitSize: number;
    productId: string;
    product: ProductMessage;
}

interface ItemChanges {
    energyContentChanged: boolean;
    isoSpecChanged: boolean;
    packTypeChanged: boolean;
    priceChanged: boolean;
    productIdChanged: boolean;
    unitChanged: boolean;
    unitSizeChanged: boolean;
    unitsChanged: boolean;
}

export interface ItemModel {
    index: number;
    new?: boolean;
    added: boolean;
    baseProduct?: ProductMessage;
    baseProductId?: string;
    changes: ItemChanges;
    deleted: boolean;
    packType: PackType;
    product: ProductMessage;
    productId: string;
    total: MoneyMessage;
    translationFailed: boolean;
    unit: Unit;
    unitSize: number;
    units: number;
    volume: number;
    price?: MoneyMessage;
    physicalSupplierName?: string;
    isoSpec?: string;
    energyContent?: EnergyContentMessage;
    isComplete(): boolean;
    isCompleteWithPrices(): boolean;
    compute(): void;
    initWithProduct(product: ProductMessage): void;
    updateWithItem(item: ItemForInitialisation): void;
    isTranslationMissing(): boolean;
}
