import { useNavigate } from '@tanstack/react-router';
import { Formik } from 'formik';
import moment from 'moment-timezone';

import { EnergyContentUnit } from '../../../../types/EnergyContentMessage';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { useOfferEdit } from '../hooks/useOfferEdit';

import { OfferEditForm } from './OfferEditForm';

export const OfferEditFormikWrapper = (props) => {
    const offerEditMutation = useOfferEdit();
    const navigate = useNavigate();
    const { addErrorToast } = useToasts();
    const role = useRole();

    const validate = (values) => {
        const errors = {};

        if (!values.dateDelivery) {
            errors.dateDelivery = 'REQUIRED';
        }

        if (!values.supplyMode) {
            errors.supplyMode = 'REQUIRED';
        }

        if (
            !values.paymentTermReference ||
            (!values.paymentTermReferenceDays && values.paymentTermReference !== 'PREPAYMENT')
        ) {
            errors.paymentTermReference = 'REQUIRED';
        }

        if (values.items.every((item) => item.deleted) || !values.hasValidProducts(role.isSupplier())) {
            errors.invalidProducts = true;
        }

        return errors;
    };

    const onSubmit = async (values, actions) => {
        try {
            const utcIsoDateDelivery = moment.utc(values.dateDelivery).startOf('day').toISOString();

            await offerEditMutation.mutateAsync({
                offerId: values.id,
                offer: {
                    ...values,
                    dateDelivery: utcIsoDateDelivery,
                    buyerReference: values.buyerReference !== '' ? values.buyerReference : null,
                    vendorReference: values.vendorReference !== '' ? values.vendorReference : null,
                    surveyor:
                        values.surveyor?.name === '' && values.surveyor?.emailAddress === '' ? null : values.surveyor,
                    items: values.items.map((item) => {
                        return {
                            ...item,
                            energyContent: item.energyContent?.value
                                ? { value: Number(item.energyContent.value), unit: EnergyContentUnit.MJKG }
                                : null,
                        };
                    }),
                },
            });

            navigate({ to: '/fuel/offer/$id', params: { id: values.id } });
        } catch (error) {
            const errorCode = error?.response?.data?.errorCode;

            if (errorCode === 'BUYER_REF_TAKEN') {
                actions.setFieldError('buyerReference', true);
            } else {
                addErrorToast(translate('offer.edit.fuel.errorToast'));
            }
        } finally {
            actions.setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={props.data}
            validateOnMount
            validate={validate}
            component={OfferEditForm}
            onSubmit={onSubmit}
        />
    );
};
