import { ComponentType } from 'react';

import { Box } from '../../common/ui/Box';
import { Tooltip } from '../Tooltip/Tooltip';

import { SegmentedControlRadioGroup } from './components/SegmentedControlRadioGroup';
import { SegmentedControlRadioGroupItem } from './components/SegmentedControlRadioGroupItem';

type IconComponentType = ComponentType<{ width?: number; height?: number }>;

type AtLeastLabelOrIcon =
    | { label: string; Icon?: IconComponentType; tooltipLabel?: never }
    | { label?: never; Icon: IconComponentType; tooltipLabel: string };

export type SegmentedControlOption<T extends string> = {
    value: T;
} & AtLeastLabelOrIcon;

export interface SegmentedControlProps<T extends string> {
    options: SegmentedControlOption<T>[];
    value: T;
    onChange: (value: T) => void;
}

export const SegmentedControl = <T extends string>({ options, onChange, value }: SegmentedControlProps<T>) => {
    return (
        <SegmentedControlRadioGroup onValueChange={onChange} value={value}>
            {options.map((option) => {
                if (option.Icon && option.label) {
                    return (
                        <SegmentedControlRadioGroupItem key={option.value} value={option.value}>
                            <Box display="flex" gap={2} flexWrap="nowrap">
                                <option.Icon width={16} height={16} />
                                {option.label}
                            </Box>
                        </SegmentedControlRadioGroupItem>
                    );
                }

                if (option.Icon && option.tooltipLabel) {
                    return (
                        <SegmentedControlRadioGroupItem key={option.value} value={option.value}>
                            <Tooltip label={option.tooltipLabel} showArrow>
                                <Box width="100%" height="100%">
                                    <option.Icon width={16} height={16} />
                                </Box>
                            </Tooltip>
                        </SegmentedControlRadioGroupItem>
                    );
                }

                return (
                    <SegmentedControlRadioGroupItem key={option.value} value={option.value}>
                        {option.Icon ? <option.Icon width={16} height={16} /> : null}
                        {option.label ? option.label : null}
                    </SegmentedControlRadioGroupItem>
                );
            })}
        </SegmentedControlRadioGroup>
    );
};
