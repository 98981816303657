import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const SegmentedControlRadioGroupItem = styled(RadixRadioGroup.Item)`
    font-weight: ${get('fontWeights.medium')};
    font-size: ${get('fontSizes.1')}px;
    line-height: 16px;
    border-radius: ${get('radii.1')}px;
    border: none;
    padding: ${get('space.2')}px 12px;

    transition:
        background-color 150ms ease-in-out,
        box-shadow 150ms ease-in-out;

    &[data-state='checked'] {
        color: ${get('colors.accent.foreground')};
        box-shadow: ${get('shadows.small')};
        background: linear-gradient(
            0deg,
            ${get('colors.background.subtle')} 0%,
            ${get('colors.background.default')} 100%
        );
    }
`;
