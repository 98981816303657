import { useState } from 'react';

import { ApiKeyMessage } from '../../../../../types/ApiKeyMessage';
import { UserType } from '../../../../../types/UserType';
import { IconButton } from '../../../../cdl/IconButton/IconButton';
import { Modal } from '../../../../cdl/Modal/Modal';
import { Text } from '../../../../cdl/Text/Text';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconPencil } from '../../../../common/icons/cdl/Pencil';
import { Box } from '../../../../common/ui/Box';
import { useApiKeyUpdate } from '../hooks/useApiKeyUpdate';

import { ApiKeyFormState } from './ApiKeyBaseForm';
import { ApiKeyForm } from './ApiKeyForm';

interface ApiKeyActionsProps {
    apiKey: ApiKeyMessage;
    groupId: string;
    userType: UserType.CUSTOMER | UserType.SUPPLIER;
}

export const EditApiKeyButton = (props: ApiKeyActionsProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { addErrorToast } = useToasts();

    const apiKeyUpdateMutation = useApiKeyUpdate();

    const onSubmit = async (values: ApiKeyFormState) => {
        const companyAccesses = values.companyIds.map((companyId) => {
            return {
                companyId: companyId,
                accessLevel: values.companyAccessLevel!,
            };
        });

        await apiKeyUpdateMutation.mutateAsync(
            {
                id: props.apiKey.id,
                apiKeyMessage: {
                    ...props.apiKey,
                    name: values.name,
                    companyAccesses,
                },
            },
            {
                onSuccess: () => {
                    setIsOpen(false);
                },
                onError: () => {
                    addErrorToast(translate('apiKey.updateError'));
                },
            }
        );
    };

    return (
        <>
            <IconButton emphasis="transparent" Icon={IconPencil} onClick={() => setIsOpen(true)} />
            <Modal isOpen={isOpen} dismissible={false} scrollable={false}>
                <Box padding={6} display="grid" gap={6}>
                    <Text variant="title">{translate('apikey.updatetitle')}</Text>

                    <ApiKeyForm
                        apiKey={props.apiKey}
                        onSubmit={onSubmit}
                        groupId={props.groupId}
                        userType={props.userType}
                        onCancel={() => setIsOpen(false)}
                        buttonText={translate('btn.save.default')}
                    />
                </Box>
            </Modal>
        </>
    );
};
