import { useState } from 'react';

import { UserType } from '../../../../../types/UserType';
import { Button } from '../../../../cdl/Button/Button';
import { Modal } from '../../../../cdl/Modal/Modal';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconPlus } from '../../../../common/icons/cdl/Plus';

import { CreateApiKeyModalContent } from './CreateApiKeyModalContent';

interface CreateApiKeyButtonProps {
    groupId: string;
    userType: UserType.CUSTOMER | UserType.SUPPLIER;
}

export const CreateApiKeyButton = ({ groupId, userType }: CreateApiKeyButtonProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button onClick={() => setIsOpen(true)} leadingVisual={<IconPlus width={16} height={16} />}>
                {translate('apikey.add')}
            </Button>
            <Modal isOpen={isOpen} dismissible={false} scrollable={false}>
                <CreateApiKeyModalContent userType={userType} groupId={groupId} onDismiss={() => setIsOpen(false)} />
            </Modal>
        </>
    );
};
